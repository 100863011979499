import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { saveAs } from 'file-saver';
import downloadFile from 'utils/downloadFile';

import {
  getUserGuid,
  getGroupGuid
} from 'slices/sliceHelpers';

// Slice
const dchatSlice = createSlice({
  name: 'dchat',
  initialState: {
    current_sitrep_id: null,
    dchat: {},
    dchatId: null,
    status: 'idle',
    error: null,
    streamtext: '',  // New state variable for websocket stream text
    dgptSitrepToInitialIncidentReport: undefined,
    sitreps: [],
    isInitSitrepLoaded: true,
    isFetchSitrepsLoaded: true,
    isRefreshSitrepLoaded: true,
    isUpdateSitrepSectionLoaded: true,
    isAddSitrepSectionLoaded: true,
    isRemoveSitrepSectionLoaded: true,
    isFetchGroupAIUsageLoaded: true,
    groupAIUsage: {},
    isFetchAllGroupsAIUsageLoaded: true,
    isUploadSOPLoaded: true,
    isFetchSOPsRequestLoaded: true,
    SOPs: [],
    isDeleteSOPLoaded: true,
    isInitPlanAISuggestionsLoaded: true,
    isFetchPlanAISuggestionsLoaded: true,
    planAISuggestions: {},
    isExportSitrepToPDFLoaded: true,
    exportSitrepToPdfResult: undefined,
  },
  reducers: {
    setCurrentSitrepId: (state, action) => {
      state.current_sitrep_id = action.payload;
    },
    pollDChatSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    postDChatSuccess: (state, action) => {
      state.dchatId = action.payload;
      // Nothing since polling is used to fetch data
    },
    postCancelRunSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setDchatStream: (state, action) => {
      state.streamtext = action.payload;  // Update stream text
    },
    setPostDchatInit: (state, action) => {
      state.streamtext = '';  // Update stream text
    },
    setSelectedDChat: (state, action) => {
      state.dchat = action.payload; // Set selected chat
    },
    setDgptSitrepToInitialIncidentReportSummary: (state, action) => {
      state.dgptSitrepToInitialIncidentReport = action.payload;
    },
    setInitSitrepLoaded: (state, action) => {
      state.isInitSitrepLoaded = action.payload;
    },
    initSitrepsSuccess: (state, action) => {
      let sitreps = [];
      if (!!state.sitreps.find((s) => s.id === action.payload.id)) {
        sitreps = [
          ...state.sitreps.filter((s) => s.id !== action.payload.id),
          { id: action.payload },
        ];
      } else {
        sitreps = [...state.sitreps, { id: action.payload }];
      }
      state.sitreps = sitreps;
    },
    setFetchSitrepsLoaded: (state, action) => {
      state.isFetchSitrepsLoaded = action.payload;
    },
    fetchSitrepsSuccess: (state, action) => {
      let sitreps = [];
      if (!!state.sitreps.find((s) => s.id === action.payload.id)) {
        sitreps = [
          ...state.sitreps.filter((s) => s.id !== action.payload.id),
          action.payload,
        ];
      } else {
        sitreps = [...state.sitreps, action.payload];
      }
      state.sitreps = sitreps;
    },
    setRefreshSitrepLoaded: (state, action) => {
      state.isRefreshSitrepLoaded = action.payload;
    },
    setUpdateSitrepSectionLoaded: (state, action) => {
      state.isUpdateSitrepSectionLoaded = action.payload;
    },
    setAddSitrepSectionLoaded: (state, action) => {
      state.isAddSitrepSectionLoaded = action.payload;
    },
    setRemoveSitrepSectionLoaded: (state, action) => {
      state.isRemoveSitrepSectionLoaded = action.payload;
    },
    setFetchGroupAIUsageLoaded: (state, action) => {
      state.isFetchGroupAIUsageLoaded = action.payload;
    },
    fetchGroupAIUsageSuccess: (state, action) => {
      let newGroupAIUsage = { ...state.groupAIUsage };
      newGroupAIUsage[action.payload.group_guid] = action.payload.usage;
    },
    setFetchAllGroupsAIUsageLoaded: (state, action) => {
      state.isFetchAllGroupsAIUsageLoaded = action.payload;
    },
    fetchAllGroupsAIUsageSuccess: (state, action) => {
      state.isFetchAllGroupsAIUsageLoaded = action.payload;
    },
    setUploadSOPLoaded: (state, action) => {
      state.isUploadSOPLoaded = action.payload;
    },
    setFetchSOPsLoaded: (state, action) => {
      state.isFetchSOPsRequestLoaded = action.payload;
    },
    fetchSOPsSuccess: (state, action) => {
      state.SOPs = action.payload.SOPs;
    },
    setDeleteSOPsLoaded: (state, action) => {
      state.isDeleteSOPLoaded = action.payload;
    },
    setInitPlanAISuggestionsLoaded: (state, action) => {
      state.isInitPlanAISuggestionsLoaded = action.payload;
    },
    setFetchPlanAISuggestionsLoaded: (state, action) => {
      state.isFetchPlanAISuggestionsLoaded = action.payload;
    },
    fetchPlanAISuggestionSuccess: (state, action) => {
      let newPlanAISuggestions = { ...state.planAISuggestions };
      newPlanAISuggestions[action.payload.mei_type] = action.payload.suggestions;
      state.planAISuggestions = newPlanAISuggestions;
    },
    setExportSitrepToPDFLoaded: (state, action) => {
      state.isExportSitrepToPDFLoaded = action.payload;
    },
    exportSitrepToPDFSuccess: (state, action) => {
      state.exportSitrepToPdfResult = action.payload;
    },
  },
});

export const {
  setCurrentSitrepId,
  pollDChatSuccess,
  postDChatSuccess,
  postCancelRunSuccess,
  setError,
  setStatus,
  setDchatStream,  // New action for setting stream text
  setPostDchatInit,
  setSelectedDChat,
  setDgptSitrepToInitialIncidentReportSummary,
  setInitSitrepLoaded,
  initSitrepsSuccess,
  setFetchSitrepsLoaded,
  fetchSitrepsSuccess,
  setRefreshSitrepLoaded,
  setUpdateSitrepSectionLoaded,
  setAddSitrepSectionLoaded,
  setRemoveSitrepSectionLoaded,
  setFetchGroupAIUsageLoaded,
  fetchGroupAIUsageSuccess,
  fetchGroupAIUsageError,
  setFetchAllGroupsAIUsageLoaded,
  fetchAllGroupsAIUsageSuccess,
  setUploadSOPLoaded,
  setFetchSOPsLoaded,
  fetchSOPsSuccess,
  setDeleteSOPsLoaded,
  setInitPlanAISuggestionsLoaded,
  setFetchPlanAISuggestionsLoaded,
  fetchPlanAISuggestionSuccess,
  setExportSitrepToPDFLoaded,
  exportSitrepToPDFSuccess,
} = dchatSlice.actions;

export default dchatSlice.reducer;

export const pollDChat = (dchatId) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(setStatus('loading'));
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);

    const data = {
      id: dchatId,
      dchat_id: dchatId,
      user_guid: user_guid,
      current_sitrep_id: current_sitrep_id,
      group_guid: group_guid,
    };

    // Initialize start time and a flag to track if the toast has been shown
    const startTime = Date.now();
    let toastShown = false;

    // Polling mechanism
    interval = setInterval(() => {
      // Check if 2 minutes have passed and the toast hasn't been shown yet
      if (!toastShown && Date.now() - startTime > 120000) { // 120000 ms = 2 minutes
        toast.info(
          "Large datasets take time to process. Try selecting fewer files for a faster response.",
          { ...toastConfig, autoClose: false }
        );
        toastShown = true; // Prevent the toast from showing multiple times
      }

      axios
        .get(`${config.apiGateway.disasterchat}/poll`, { params: data })
        .then((response) => {
          const dchat = response.data;
          if (!dchat) {
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else if (dchat.status === 'Error') {
            toast.error(dchat?.err_msg, { ...toastConfig, autoClose: false });
            dispatch(setError("DChat Error. Cancel run and try again."));
            dispatch(cancelRun(dchat));
            clearInterval(interval);
          } else if (dchat.status === 'Complete') {
            dispatch(pollDChatSuccess(dchat));
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else {
            dispatch(setStatus('ongoing'));
            dispatch(pollDChatSuccess(dchat));
          }
        })
        .catch((error) => {
          console.error('Error checking DChat status:', error);
          dispatch(setError(error.message));
          dispatch(setStatus('failed'));
          toast.error(
            "Pratus error: please contact support@disastertech.com",
            { ...toastConfig, autoClose: false }
          );
          clearInterval(interval);
        });
    }, 500); // Check every half second
  };
};

export const postDChat = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  dispatch(setPostDchatInit())
  try {
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);
    const payload = {
      ...data,
      user_guid,
      current_sitrep_id,
      group_guid: group_guid,
    };
    let response
    if(!!data.dchat_id)
    {
      response = await axios.put(`${config.apiGateway.disasterchat}`, payload);
    }
    else
    {
      response = await axios.post(`${config.apiGateway.disasterchat}`, payload);
    }
    dispatch(postDChatSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data));
  } catch (error) {
    toast.error(error.message, { ...toastConfig, autoClose: false });
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

export const cancelRun = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  try {
    const response = await axios.post(`${config.apiGateway.disasterchat}/cancel`, data);
    dispatch(postCancelRunSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data));
  } catch (error) {
    toast.error(error.message, { ...toastConfig, autoClose: false });
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

export const exportSitrepToPdf = ({ sitrepMarkdown, sitrepId, output }) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  dispatch(setExportSitrepToPDFLoaded(false));
  dispatch(exportSitrepToPDFSuccess(null));
  
  try {
    const response = await axios(config.apiGateway.exportSitrepToPdf, {
      method: 'POST',
      data: JSON.stringify({
        sitrepMarkdown,
        output,
      }),
    });

    const fileName = 'Sitrep-' + sitrepId;
    const data = response.data || {};
    if (output === 'PDF') {
      downloadFile(fileName, output, data);
    } else if (output === 'DOCX') {
      // const statementAsDocumentFile = response.data || {};
      // let statementBlob;
      // // Convert the data to be blob-able
      // let binary = atob(statementAsDocumentFile.replace(/\s/g, ''));
      // let len = binary.length;
      // let buffer = new ArrayBuffer(len);
      // let view = new Uint8Array(buffer);
      // for (let i = 0; i < len; i++) {
      //   view[i] = binary.charCodeAt(i);
      // }
      // statementBlob = new Blob([view], { type: 'application/docx' });
      saveAs(response.data, fileName + '.' + output);
    }
    dispatch(setStatus('succeeded'));
    dispatch(exportSitrepToPDFSuccess(data));
    dispatch(setExportSitrepToPDFLoaded(true));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setStatus('failed'));
    dispatch(setExportSitrepToPDFLoaded(true));
  }
};

export const initSitrep = ({ chatDGPTSession, source, source_id, name, selectedDatetime }) => async (dispatch, getState) => {
  dispatch(setInitSitrepLoaded(false));
  const { user_guid } = getState().app.user;
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.initSitrep, {
      method: 'POST',
      data: JSON.stringify({
        chatDGPTSession,
        source,
        source_id,
        group_guid,
        user_guid,
        name,
        selectedDatetime,
      }),
    });

    const sitrepId = response.data || {};
    dispatch(initSitrepsSuccess(sitrepId));
    dispatch(setInitSitrepLoaded(true));
    dispatch(fetchSitreps({ sitrepId: sitrepId }));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setInitSitrepLoaded(true));
  }
};

export const fetchSitreps = ({ sitrepId, source_id }) => async (dispatch, getState) => {
  dispatch(setFetchSitrepsLoaded(false));
  const { user_guid } = getState().app.user;
  const { group_guid } = getState().app.currentlySelectedGroup;

  // Now poll for results
  const interval = setInterval(() => {
    axios(config.apiGateway.fetchSitreps, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        source_id,
        user_guid,
        group_guid,
      }),
    })
      .then((pollResponse) => {
        const sitrep = pollResponse.data;
        if (!sitrep || sitrep.status === 'Error') {
          console.error('DisasterGPT processing error.  Please try again.')
          clearInterval(interval);
          dispatch(setFetchSitrepsLoaded(true));
        } else if (sitrep.status === 'Summarized') {
          dispatch(fetchSitrepsSuccess(sitrep));
          clearInterval(interval);
          dispatch(setFetchSitrepsLoaded(true));
        } else {
          dispatch(fetchSitrepsSuccess(sitrep));
        }
      })
      .catch((error) => {
        console.error('Error checking DGPT task status:', error);
        clearInterval(interval);
        dispatch(setFetchSitrepsLoaded(true));
      });
  }, 500); // Check every half second
};

export const refreshSitrep = ({ id, name, selectedDatetime, sections = [] }) => async (dispatch, getState) => {
  dispatch(setRefreshSitrepLoaded(false));
  const { user_guid } = getState().app.user;
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.refreshSitrep, {
      method: 'POST',
      data: JSON.stringify({
        id,
        user_guid,
        group_guid,
        name,
        selectedDatetime,
        sections, // Include sections here
      }),
    });
    const sitrepId = response.data || {};
    dispatch(setRefreshSitrepLoaded(true));
    dispatch(fetchSitreps({ sitrepId: sitrepId }));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setRefreshSitrepLoaded(true));
  }
};

export const updateSitrepSection = ({
    sitrepId,
    sectionId,
    sectionText,
    sectionTitle,
  }) => async (dispatch) => {
    dispatch(setUpdateSitrepSectionLoaded(false));

    try {
        const response = await axios (config.apiGateway.updateSitrepSection, {
        method: 'POST',
        data: JSON.stringify({
          sitrepId,
          sectionId,
          sectionText,
          sectionTitle,
        }),
      })
        const sitrepIdData = response.data || {};
        dispatch(setUpdateSitrepSectionLoaded(true));
        dispatch(fetchSitreps({ sitrepId: sitrepIdData }));
    } catch (error) {
        console.error('Error with DGPT task:', error);
        dispatch(setUpdateSitrepSectionLoaded(true));
    }
};

export const addSitrepSection = ({ sitrepId, sectionId, sectionTitle, selectedDatetime }) => async (dispatch, getState) => {
  dispatch(setAddSitrepSectionLoaded(false));
  const { user_guid } = getState().app.user;
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.addSitrepSection, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        sectionId,
        sectionTitle,
        user_guid,
        group_guid,
        selectedDatetime,
      }),
    });
    const sitrepIdData = response.data || {};
    dispatch(setAddSitrepSectionLoaded(true));
    dispatch(fetchSitreps({ sitrepId: sitrepIdData }));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setAddSitrepSectionLoaded(true));
  }
};

export const removeSitrepSection = ({ sitrepId, sectionId }) => async (dispatch) => {
  dispatch(setRemoveSitrepSectionLoaded(false));

  try {
    const response = await axios(config.apiGateway.removeSitrepSection, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        sectionId,
      }),
    });
    const sitrepIdData = response.data || {};
    dispatch(setRemoveSitrepSectionLoaded(true));
    dispatch(fetchSitreps({ sitrepId: sitrepIdData }));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setRemoveSitrepSectionLoaded(true));
  }
};

export const fetchGroupAIUsage = ({group_guid}) => async (dispatch) => {
  dispatch(setFetchGroupAIUsageLoaded(false));

  try {
    const response = await axios(config.apiGateway.fetchGroupAIUsage, {
      method: 'POST',
      data: JSON.stringify({ group_guid }),
    });
    dispatch(fetchGroupAIUsageSuccess({ group_guid, usage: response.data }));
    dispatch(setFetchGroupAIUsageLoaded(true));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setFetchGroupAIUsageLoaded(true));
  }
};

export const fetchAllGroupsAIUsage = () => async (dispatch, getState) => {
  dispatch(setFetchAllGroupsAIUsageLoaded(false));

  try {
    const response = await axios(config.apiGateway.fetchAllGroupsAIUsage, {
      method: 'POST',
    })

    const sitrepId = response.data || {};
    dispatch(fetchAllGroupsAIUsageSuccess(sitrepId));
    dispatch(setFetchAllGroupsAIUsageLoaded(true));
  } catch (error) {
    
    console.error('Error withq DGPT task:', error);
    dispatch(setFetchAllGroupsAIUsageLoaded(true));
  }
};

export const uploadSOP = (file) => async (dispatch, getState) => {
  const state = getState()
  const {currentIncident} = state.app;

  dispatch(setUploadSOPLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('group_guid', group_guid);
  formData.append('tenantId', currentIncident?.tenant_id); // Append tenantId
  formData.append('sharepointDriveId', currentIncident?.sharepoint_location); // Append sharepointDriveId

  try {
    const fileUploadInstance = axios.create({
      ...axios.defaults,
      headers: {
        ...axios.headers,
        'Content-Type': undefined,
      },
    });

    fileUploadInstance.interceptors.request.use((config) => {
      const token = sessionStorage['accessToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const response = await fileUploadInstance // Add return here to ensure the action returns a promise
      .post(config.apiGateway.uploadSOP, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
    dispatch(setUploadSOPLoaded(true));
    dispatch(fetchSOPs());
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setUploadSOPLoaded(true));
  }
};

export const fetchSOPs = () => async (dispatch, getState) => {
  dispatch(setFetchSOPsLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.fetchSOPs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    });

    dispatch(fetchSOPsSuccess({ group_guid, SOPs: response.data }));
    dispatch(setFetchSOPsLoaded(true));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setFetchSOPsLoaded(true));
  }
};

export const deleteSOP = (id) => async (dispatch, getState) => {
  dispatch(setDeleteSOPsLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.deleteSOP, {
      method: 'POST',
      data: JSON.stringify({
        id,
      }),
    })

    dispatch(setDeleteSOPsLoaded(true));
    dispatch(fetchSOPs());
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setDeleteSOPsLoaded(false));
  }
};

export const checkDeletedSOPFiles = (vectorStoreId) => async (dispatch, getState) => {
  const { group_guid } = getState().app.currentlySelectedGroup;

  try {
    const response = await axios(config.apiGateway.deletedSOPFiles, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        vector_store_id: vectorStoreId
      }),
    })

    if (response?.data?.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error with DGPT task:', error);
    return false;
  }
};

export const initPlanAISuggestion = ({
  reference,
  reference_id,
  input,
  sitrep_id,
  mei_type,
}) => async (dispatch, getState) => {
  dispatch(setInitPlanAISuggestionsLoaded(false));
  const { group_guid } = getState().app.currentlySelectedGroup;
  const { user_guid } = getState().app.user;
  const { id } = getState().app.currentIncident;

  try {
    const response = axios(config.apiGateway.initPlanAISuggestion, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_guid,
        incident_id: id,
        reference,
        reference_id,
        input,
        sitrep_id,
        mei_type,
      }),
    })

    const plan_id = response.data;
    dispatch(setInitPlanAISuggestionsLoaded(true));
    dispatch(fetchPlanAISuggestion({
      plan_id,
      reference,
      reference_id,
      mei_type,
    }));
  } catch (error) {
    console.error('Error with DGPT task:', error);
    dispatch(setInitPlanAISuggestionsLoaded(true));
  }
};

export const fetchPlanAISuggestion = ({
  plan_id,
  reference,
  reference_id,
  mei_type,
}) => {
  dispatch(setFetchPlanAISuggestionsLoaded(false));

  return (dispatch, getState) => {
    const interval = setInterval(() => {
      axios(config.apiGateway.fetchPlanAISuggestion, {
        method: 'POST',
        data: JSON.stringify({
          plan_id,
        }),
      })
        .then((pollResponse) => {
          const suggestionResponse = pollResponse.data;
          if (!suggestionResponse || suggestionResponse.status === 'Error') {
            dispatch(setFetchPlanAISuggestionsLoaded(true));
            clearInterval(interval);
          } else if (suggestionResponse.status === 'Complete') {
            dispatch(
              fetchPlanAISuggestionSuccess({
                reference,
                reference_id,
                suggestions: suggestionResponse.output,
                mei_type,
              })
            );
            dispatch(setFetchPlanAISuggestionsLoaded(true));
            clearInterval(interval);
          }
        })
        .catch((error) => {
          console.error('Error checking DGPT task status:', error);
          dispatch(InitPlanAISuggestionsError(error));
          clearInterval(interval);
        });
      }, 500); // Check every half second
  }
};

export function updateSuggestionsAfterSave({ suggestions, mei_type }) {
    return {};
  }