import { Modal } from 'react-bootstrap';
import * as microsoftTeams from '@microsoft/teams-js';

export default function TeamsAppErrorDialog({ show, error, clientId }) {
  
  
  microsoftTeams.initialize();

  // Get the current theme
  microsoftTeams.getContext((context) => {
      if (context.theme === "dark" || context.theme === "contrast") {
      // Set text color to white for dark and high contrast themes
        document.querySelectorAll("body").forEach((item) => {
          item.style.backgroundColor = "transparent";
          item.style.color = "white";
        });

      } else {
        document.querySelectorAll("body").forEach((item) => {
          item.style.backgroundColor = "transparent";
          item.style.color = "black";
        });
        document.querySelectorAll(".pb-5").forEach((item) => {
          item.style.backgroundColor = "white";
        })
      }
  });

  const ErrorCodeInstructions = (error) => {
    if (error.includes('consented') 
      || error.includes('AADSTS500011')) 
    {
      return (
        <>
          <p>PRATUS requires that the following steps be completed.</p>
          <ol>
            <li>
              <p>This app to first be installed in your organization&apos;s app catalog.</p>
            </li>
            <li>
              <p>An administrator would then need to grant the required permissions for all users.</p>
            </li>
          </ol>
          <p>To continue after these steps are complete, please uninstall and re-add the app in your personal scope.</p>
        </>
      )
    } else {
      return (
        <>
          <p>We&apos;re sorry, Pratus is having trouble authenticating with Teams.</p>
          {error && (
            <p>{error}</p>
          )}
        </>
      )
    }
  }

  return (
    <>
      <Modal
        show={show}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="pb-5">
          <div className="d-flex flex-column align-items-center">
            {ErrorCodeInstructions(error)}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}