import {
  fetchUserWithSubscription,
  upgradeUserSubscription,
} from 'actions/subscriptionManagementActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BulkUpgradeSubscriptionDialog } from './BulkUpgradeSubscriptionDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { SubscriptionAddOnDialog } from './SubscriptionAddOnDialog';

export const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allUsersWithSubscription = useSelector(
    (state) => state?.app?.allUsersWithSubscription
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [pages, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [
    showBulkUpgradeSubscriptionDialog,
    setShowBulkUpgradeSubscriptionDialog,
  ] = useState(false);

  const [usersToBeUpgraded, setUsersToBeUpgraded] = useState([]);
  const [openAddOnDialog, setOpenAddOnDialog] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const statusOptions = [
    { label: 'Trial', value: 'Trial' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Paid (Incident only)', value: 'Paid (Incident only)' },
    { label: 'Paid (Exercise only)', value: 'Paid (Exercise only)' },
    { label: 'Expired', value: 'Expired' },
  ];

  const columns = [
    {
      dataField: 'userName',
      text: 'Name',
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
      formatter: (cell, row, rowIndex) => {
        return <>{row?.profile_settings?.userName}</>;
      },
    },
    {
      dataField: 'email_address',
      text: 'Email',
      attrs: { title: 'Email' },
    },
    {
      dataField: 'current_status',
      text: 'Subscription Status',
      formatter: (cell, row, rowIndex) => {
        return (
          <div style={{ width: '200px' }} key={row?.current_status}>
            <StylishNewSelect
              options={statusOptions}
              value={
                row?.current_status
                  ? { label: row?.current_status, value: row?.current_status }
                  : null
              }
              placeholder="Select a Status"
              onChange={(e) =>
                upgradeSubscriptionHandler(
                  row?.subscription_id,
                  e,
                  row?.subscribed_mass_notification,
                  row?.subscribed_international_messaging
                )
              }
              isDisabled={false}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor:
                    row?.current_status === 'Trial'
                      ? '#4A98CE !important'
                      : row?.current_status === 'Paid'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Incident only)'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Exercise only'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Expired'
                      ? '#FF2323 !important'
                      : null,
                  background:
                    row?.current_status === 'Trial'
                      ? '#4A98CE !important'
                      : row?.current_status === 'Paid'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Incident only)'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Paid (Exercise only'
                      ? '#37CC70 !important'
                      : row?.current_status === 'Expired'
                      ? '#FF2323 !important'
                      : null,
                }),
                indicatorSeparator: (baseStyles, state) => ({
                  ...baseStyles,
                  background: row?.current_status ? `#fff !important` : null,
                }),
              }}
            />
          </div>
        );
      },
      attrs: { title: 'Subscription Status' },
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Profile"
          cell={cell}
          row={row}
          onView={() =>
            row?.user_guid ? navigate(`/profile/${row?.user_guid}`) : undefined
          }
          onAddOn={() => {
            if (row?.user_guid) {
              setOpenAddOnDialog(true);
              setSubscriptionData(row);
            }
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6">
              <h6 className="mb-2">Mobile Phone No.</h6>
              {row?.profile_settings?.mobile_phone_number
                ? row?.profile_settings?.mobile_phone_number
                : '-'}
            </div>
            <div className="col-md-6">
              <h6 className="mb-2">Home Phone No.</h6>
              {row?.profile_settings?.home_phone_number
                ? row?.profile_settings?.home_phone_number
                : '-'}
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    hideSelectAll: false,
    selected: usersToBeUpgraded.map((i) => i.user_guid),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, checked, disabled }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} checked={checked} disabled={disabled} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let users = usersToBeUpgraded.map((i) => i.user_guid);
      if (users.includes(row.user_guid)) {
        users = users.filter((i) => i !== row.user_guid);
      } else {
        users.push(row.user_guid);
      }
      setUsersToBeUpgraded(
        allUsersWithSubscription.users
          .filter((i) => users.includes(i.user_guid))
          .map((i) => ({
            user_guid: i.user_guid,
            subscription_id: i.subscription_id,
          }))
      );
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const users = rows.map((i) => ({
          user_guid: i.user_guid,
          subscription_id: i.subscription_id,
        }));
        setUsersToBeUpgraded(users);
      } else {
        setUsersToBeUpgraded([]);
      }
    },
  };

  useEffect(() => {
    dispatch(fetchUserWithSubscription());
  }, []);

  const onChangeSearchTerm = (value) => {
    setSearchTerm(value);
    dispatch(fetchUserWithSubscription(pages, value));
  };

  const handlePageClick = (page) => {
    setPage(page);
    dispatch(fetchUserWithSubscription(page));
  };

  const upgradeSubscriptionHandler = (
    subscriptionId,
    value,
    subscribed_mass_notification,
    subscribed_international_messaging
  ) => {
    setSearchTerm('');
    dispatch(
      upgradeUserSubscription(
        subscriptionId,
        value.value,
        subscribed_mass_notification,
        subscribed_international_messaging
      )
    );
  };

  return (
    <>
      <div className="site-main">
        <div className="site-main__profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button onClick={() => navigate(-1)}>
                        <SharedIcon
                          iconName="keyboard_arrow_left"
                          classes="me-2"
                        />
                        Back
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Subscription Management
                    </li>
                  </ol>
                </nav>
                <div className="d-flex mb-4">
                  <StylishNewSearchBar
                    value={searchTerm}
                    onChangeSearchTerm={onChangeSearchTerm}
                    placeholder={'Search by email..'}
                  />
                  {!!usersToBeUpgraded.length && (
                    <StylishNewButton
                      className={'button--primary ms-auto'}
                      onClick={() => setShowBulkUpgradeSubscriptionDialog(true)}
                    >
                      Bulk Upgrade
                    </StylishNewButton>
                  )}
                </div>
                <div className="form-block">
                  <p className="weight-600">
                    {allUsersWithSubscription.total || 0} Member(s)
                  </p>
                  <StylishNewTable
                    keyField={'user_guid'}
                    columns={columns}
                    rows={allUsersWithSubscription.users || []}
                    expandRow={expandRow}
                    selectRow={selectRow}
                    pagination={true}
                    page={pages}
                    perPage={perPage}
                    total={allUsersWithSubscription.total}
                    onPageChange={(page) => handlePageClick(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBulkUpgradeSubscriptionDialog && (
        <BulkUpgradeSubscriptionDialog
          show={showBulkUpgradeSubscriptionDialog}
          onClose={() => {
            setShowBulkUpgradeSubscriptionDialog(false);
            setUsersToBeUpgraded([]);
          }}
          users={usersToBeUpgraded}
        />
      )}

      {openAddOnDialog && (
        <SubscriptionAddOnDialog
          show={openAddOnDialog}
          onClose={() => {
            setOpenAddOnDialog(false);
            setSubscriptionData(null);
          }}
          subscriptionData={subscriptionData}
        />
      )}
    </>
  );
};
