import React, { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import StylishNewSelect, {
  OptionType,
} from '../DesignSystems/New/StylishNewSelect';
import {
  selectGroup,
  selectGroups,
  selectIncident,
  selectIncidents,
  useAppSelector,
} from 'slices/commonSelectors';
import { useDispatch } from 'react-redux';

import {
  updateCurrentIncident,
  updateCurrentlySelectedGroup,
} from '../../actions/diceActions';

const TeamsTabConfig: React.FC = () => {
  const [theme, setTheme] = useState<string>('dark');

  const dispatch = useDispatch();

  microsoftTeams.initialize();

  const incident = useAppSelector(selectIncident);
  const incidents = useAppSelector(selectIncidents);
  const group = useAppSelector(selectGroup);
  const groups = useAppSelector(selectGroups);

  const [groupOptions, setGroupOptions] = useState<OptionType[]>([]);
  const [incidentOptions, setIncidentOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    setGroupOptions(
      groups
        .filter((group) => !group.is_closed)
        .map((group) => ({
          label: group.group_name,
          value: group.group_guid,
        }))
    );
  }, [groups]);

  useEffect(() => {
    setIncidentOptions(
      incidents
        .filter((incident) => incident.group_guid === group.group_guid)
        .map((incident) => ({
          label: incident.name,
          value: incident.id,
        }))
    );
  }, [incidents, group]);

  useEffect(() => {
    try {
      const url = window.location.origin + `/iap/${incident.id}/settings`;

      microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
        microsoftTeams.settings.setSettings({
          websiteUrl: url,
          contentUrl: url,
          entityId: 'PratusTeamTab',
          suggestedDisplayName: 'PRATUS',
        });
        saveEvent.notifySuccess();
      });
      // Set validity state to true when the page loads as there are no user settings to configure on this page.
      microsoftTeams.settings.setValidityState(true);

      // Get the current theme
      microsoftTeams.getContext((context) => {
        if (context.theme === 'dark' || context.theme === 'contrast') {
          setTheme('dark');
          document.querySelectorAll('body').forEach((item) => {
            item.style.backgroundColor = 'transparent';
            item.style.color = 'white';
          });
        } else {
          setTheme('light');
          document.querySelectorAll('body').forEach((item) => {
            item.style.backgroundColor = 'transparent';
            item.style.color = 'black';
          });
        }
      });
    } catch (e) {
      console.warn('Teams Tab Initialization Error: ', e);
    }
  }, [incident]);

  const organizationChangeHandler = (orgGuid: string) => {
    if (orgGuid !== group?.group_guid) {
      dispatch(
        updateCurrentlySelectedGroup(
          groups.find((group) => group.group_guid === orgGuid)
        )
      );
      const firstIncident = incidents.find(
        (incident) => incident.group_guid === orgGuid
      );
      dispatch(updateCurrentIncident(firstIncident));
    }
  };

  const incidentChangeHandler = (incidentId: string) => {
    if (incidentId !== incident?.id) {
      dispatch(
        updateCurrentIncident(
          incidents.find((incident) => incident.id === incidentId)
        )
      );
    }
  };

  return (
    <>
      <div className="section-select">
        <label
          className="form-label"
          style={{ color: theme === 'dark' ? 'white' : 'black' }}
        >
          Sponsor Organization
        </label>
        <StylishNewSelect
          classes={`secondary sml ${theme}`}
          options={groupOptions}
          value={
            group?.group_guid
              ? {
                  value: group.group_guid,
                  label: group.group_name,
                }
              : ''
          }
          placeholder="Select a Organization"
          onChange={(e) => {
            organizationChangeHandler(e.value);
          }}
        />
        <br />
        <label
          className="form-label"
          style={{ color: theme === 'dark' ? 'white' : 'black' }}
        >
          Incident Workspace
        </label>
        <StylishNewSelect
          classes={`secondary sml ${theme}`}
          options={incidentOptions}
          value={
            incident?.id
              ? {
                  value: incident.id,
                  label: incident.name,
                }
              : ''
          }
          placeholder="Select an Incident Workspace"
          onChange={(e) => incidentChangeHandler(e.value)}
        />
      </div>
      <br />
      <p className="teams-text">
        PRATUS - One solution for incident management and training/exercise
        planning.
      </p>
      <p className="teams-text">
        Please select an incident workspace and then click &quot;Save&quot;
        below to add PRATUS as a Tab.
      </p>
    </>
  );
};

export default TeamsTabConfig;
