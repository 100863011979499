import { productionEnv } from 'constants/debug';

const azureApiScope = productionEnv
  ? window.env.AZURE_API_SCOPE
  : process.env.REACT_APP_AZURE_API_SCOPE;

// Entra ID
const envAuthority = productionEnv
  ? window.env.AZURE_AUTHORITY
  : process.env.REACT_APP_AZURE_AUTHORITY;
export const msalConfig = {
  auth: {
    authority:
      !!envAuthority && envAuthority !== ''
        ? envAuthority
        : 'https://login.microsoftonline.com/common',
    clientId: productionEnv
      ? window.env.AZURE_CLIENT_ID
      : process.env.REACT_APP_AZURE_CLIENT_ID,
    audience: productionEnv
      ? window.env.AZURE_API_AUDIENCE
      : process.env.REACT_APP_AZURE_API_AUDIENCE,
    redirectUri: window.location.origin,
    knownAuthorities: [
      productionEnv
        ? window.env.KNOWN_AUTHORITIES
        : process.env.REACT_APP_KNOWN_AUTHORITIES,
    ],
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: [azureApiScope, 'user.read'],
};

export const ssoRequest = {
  scopes: [azureApiScope, 'user.read'],
  loginHint: 'user@disastertech.com',
};

export const tokenRequest = {
  scopes: [azureApiScope, 'user.read'],
};

// /Entra ID
