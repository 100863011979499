import PageTitle from '../headers/PageTitle';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIncident } from './hooks/incidentHooks';
import NewIncidentDialog from '../IncidentConfig/NewIncidentDialog';
import EditOperationalPeriodDialog from '../IncidentConfig/EditOperationalPeriodDialog';
import { Col, Row } from 'react-bootstrap';
import IncidentAdminToolbar from '../incidentAdminToolbar/IncidentAdminToolbar';
import StylishNewSelect from '../DesignSystems/New/StylishNewSelect';
import CloneDefaults from '../CloneDefaults/CloneDefaults';
import IncidentConfig from '../IncidentConfig/IncidentConfig';
import {
  selectGroup,
  selectGroups,
  useAppSelector,
} from '../../slices/commonSelectors';
import useGroupAdminForCurrentIncident from '../../hooks/useGroupAdminForCurrentIncident';
import { startLoading } from '../../reducers/loading/loading.action';
import { updateIncidentGroup } from '../../actions/diceActions';
import GenerateTeamSite from 'components/TemplateWizard/GenerateTeamSite';
import MobileWarningDialog from 'components/DesignSystems/MobileWarning/MobileWarningDialog';

const IAPSettings = () => {
  const [newIncidentOpen, setNewIncidentOpen] = React.useState(false);
  const [editOpPeriodOpen, setEditOpPeriodOpen] = React.useState(false);

  const incident = useIncident();

  const rbacPermissions =
    useAppSelector((state: any) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  return (
    <MobileWarningDialog>
      <div className="maincontent">
        <div className="row">
          <div className="col-md-12 mb-5">
            <PageTitle title={'Incident Settings'}>
              <StylishNewButton
                className="button--primary w-100 w-md-auto"
                disabled={!rbacPermissions['Create Group Incident']}
                onClick={() => setNewIncidentOpen(true)}
              >
                New Incident
              </StylishNewButton>
              {incident.isSuccess && (
                <StylishNewButton
                  className="button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                  disabled={!rbacPermissions['Create Group Incident']}
                  onClick={() => setEditOpPeriodOpen(true)}
                >
                  Edit Operational Period
                </StylishNewButton>
              )}
              <GenerateTeamSite />
            </PageTitle>

            <Row>
              <Col xl={3} className="order-1 order-xl-0">
                <IncidentAdminToolbar />
                <SponsorOrganization />
                <CloneDefaults />
              </Col>
              <Col xl={9} className="order-0 order-xl-1">
                <IncidentConfig />
              </Col>
            </Row>
          </div>
        </div>
        {newIncidentOpen && (
          <NewIncidentDialog onClose={() => setNewIncidentOpen(false)} />
        )}
        {editOpPeriodOpen && (
          <EditOperationalPeriodDialog
            show={true}
            onClose={() => setEditOpPeriodOpen(false)}
          />
        )}
      </div>
    </MobileWarningDialog>
  );
};
export default IAPSettings;

const SponsorOrganization = () => {
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [saveRequested, setSaveRequested] = useState(false);

  const userIsGroupAdmin = useGroupAdminForCurrentIncident();

  const currentGroup = useAppSelector(selectGroup);

  useEffect(() => {
    setSelectedGroup({
      label: currentGroup?.group_name || '',
      value: currentGroup?.group_guid || '',
    });
  }, [currentGroup]);

  const currentIncident = useAppSelector((state) => {
    return state.app.currentIncident;
  });

  const isSaveLoaded = useAppSelector((state) => {
    return state.app.isUpdateIncidentGroupLoaded;
  });

  const groups = useAppSelector((state) => {
    const rawGroups = selectGroups(state);
    return rawGroups
      .map((group) => ({
        groupGuid: group.group_guid,
        nameOfGroup: group.group_name,
        isCurrentUserPending: (!!(group.role === 'Pending')).toString(),
      }))
      .filter(({ isCurrentUserPending }) => isCurrentUserPending !== 'true')
      .map((group) => ({ label: group.nameOfGroup, value: group.groupGuid }));
  });

  useEffect(() => {
    if (isSaveLoaded && saveRequested) {
      setSaveRequested(false);
    }
  }, [isSaveLoaded, saveRequested]);

  const handleDropdownChange = (data: { label: string; value: string }) => {
    setSelectedGroup(data);
  };

  const handleSaveClick = () => {
    if (!selectedGroup) {
      return;
    }
    setSaveRequested(true);
    dispatch(startLoading());
    dispatch(
      updateIncidentGroup({
        incidentGuid: currentIncident.id,
        groupGuid: selectedGroup.value,
      })
    );
  };

  return (
    <div className="bg-gray-800 rounded p-3 p-md-4 mt-4">
      <h6 className="text-uppercase mt-0">Sponsor Organization</h6>
      <hr className="dashed my-3" />
      <span className="d-block txt--sml mb-3">
        ** You must have the proper permissions for the incident and sponsor
        organization to make changes
      </span>
      {groups && groups.length > 0 && (
        <>
          <StylishNewSelect
            options={groups}
            value={selectedGroup}
            placeholder="Select a Type"
            onChange={(e) => handleDropdownChange(e)}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={
              saveRequested ||
              !(currentIncident?.role === 'Admin' || userIsGroupAdmin)
            }
          />
        </>
      )}
      <div className="button-group flex-wrap justify-content-end mt-4">
        <StylishNewButton
          disabled={
            saveRequested ||
            !(currentIncident?.role === 'Admin' || userIsGroupAdmin)
          }
          className="button--secondary"
          onClick={() => handleSaveClick()}
        >
          Save
        </StylishNewButton>
        {saveRequested ||
          (!(currentIncident?.role === 'Admin' || userIsGroupAdmin) && (
            <span className="form-text form-error multiline">
              Only Organization Admins can change the Organization assignment.
            </span>
          ))}
      </div>
    </div>
  );
};
